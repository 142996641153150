import FamilyMemberAdmin from 'components/FamilyPlan/FamilyPlanAdmin';
import FamilyMemberBlank from 'components/FamilyPlan/FamilyMemberBlank';
import FamilyMemberChild from 'components/FamilyPlan/FamilyMemberChild';
import FamilyMemberPending from 'components/FamilyPlan/FamilyMemberPending';
import FamilyMembers from 'components/FamilyPlan/primitives/FamilyMembers';
import Legal from 'primitives/Typography/BodyCopy/Legal';
import { Child } from './types';
import { Component } from 'react';
import { GrowlIcons } from 'components/Growls/constants';
import { listFPChildren } from 'shared/api/subscription';
import type { IGetTranslateFunctionResponse } from 'redux-i18n';
import type { showNotifyGrowl as showNotifyGrowlAction } from 'state/UI/actions';

type State = {
  childrenList: Array<Child>;
};

export type Props = {
  child?: boolean;
  childParentEmail: string | null;
  email: string | null;
  profileId: number;
  sessionId: string;
  showNotifyGrowl: typeof showNotifyGrowlAction;
  translate: IGetTranslateFunctionResponse;
};

export default class FamilyPlan extends Component<Props, State> {
  state = { childrenList: [] };

  componentDidMount(): void {
    const { profileId, sessionId } = this.props;
    listFPChildren(profileId, sessionId).then(({ data }) => {
      const { children } = data;
      this.setState({ childrenList: children });
    });
  }

  inviteFPChild = (child: Child): void => {
    this.setState(({ childrenList }) => ({
      childrenList: childrenList.concat(child),
    }));
  };

  removeFPChild = (email: string): void => {
    const removeChildIndex = this.state.childrenList
      .map((activeChild: Child) => activeChild.email)
      .indexOf(email);

    this.setState(({ childrenList }) => ({
      childrenList: [
        ...childrenList.slice(0, removeChildIndex),
        ...childrenList.slice(removeChildIndex + 1),
      ],
    }));
  };

  onGrowl = (title: string, icon: GrowlIcons): void => {
    this.props.showNotifyGrowl({ title, icon });
  };

  render() {
    const { child, childParentEmail, email, profileId, sessionId, translate } =
      this.props;
    const { childrenList } = this.state;
    const NUMBER_OF_CHILDREN = 5;
    const showFamilyMembers = this.state.childrenList.map((member: Child) => (
      <FamilyMemberPending
        inviteChild={this.inviteFPChild}
        key={member.email}
        member={member}
        onGrowl={this.onGrowl}
        profileId={profileId}
        removeChild={this.removeFPChild}
        sessionId={sessionId}
        translate={translate}
      />
    ));

    const showChildView = <FamilyMemberChild />;

    const showBlankFamilyMembers = Array.from(
      { length: NUMBER_OF_CHILDREN - childrenList.length },
      (_, i) => (
        <FamilyMemberBlank
          inviteChild={this.inviteFPChild}
          key={i}
          onGrowl={this.onGrowl}
          parentEmail={email!}
          profileId={profileId}
          sessionId={sessionId}
          translate={translate}
        />
      ),
    );

    return (
      <>
        <FamilyMembers>
          <FamilyMemberAdmin
            childParentEmail={childParentEmail!}
            email={email!}
          />
          {child ? showChildView : showFamilyMembers}
          {child ? null : showBlankFamilyMembers}
        </FamilyMembers>
        <Legal>{translate('Invitees must be 14 years or older')}</Legal>
      </>
    );
  }
}
