import Add from 'components/FamilyPlan/primitives/Add';
import AddIcon from 'styles/icons/Add';
import ErrorMessage from 'components/FamilyPlan/primitives/ErrorMessage';
import FamilyMember from 'components/FamilyPlan/primitives/FamilyMember';
import FamilyMemberContainer from 'components/FamilyPlan/primitives/FamilyMemberContainer';
import FilledButton from 'primitives/Buttons/FilledButton';
import Input from 'primitives/Forms/Input';
import isValidEmail from 'utils/validate/isValidEmail';
import logger, { CONTEXTS } from 'modules/Logger';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import Row from 'components/FamilyPlan/primitives/Row';
import RowMask from 'components/FamilyPlan/primitives/RowMask';
import { Child } from './types';
import { Component } from 'react';
import { GrowlIcons } from 'components/Growls/constants';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { inviteFPChild } from 'shared/api/subscription';
import { mapErrors } from './FamilyPlanHelpers';

type State = {
  childInviteeEmail: string;
  displayError: boolean;
  errorText: string;
  expanded: boolean;
  validEmail: boolean;
};

type Props = {
  inviteChild: (child: Child) => void;
  onGrowl: (title: string, icon: GrowlIcons) => void;
  parentEmail: string;
  profileId: number;
  sessionId: string;
  translate: IGetTranslateFunctionResponse;
};

export default class FamilyMemberBlank extends Component<Props, State> {
  inviteFPChildEmail: HTMLInputElement | null | undefined;

  state = {
    childInviteeEmail: '',
    displayError: false,
    errorText: '',
    expanded: false,
    validEmail: false,
  };

  openCloseToggle = () => {
    this.setState(({ expanded }) => ({ expanded: !expanded }));
  };

  onSubmitError = (error: string) => {
    this.setState(({ childInviteeEmail }) => ({
      displayError: true,
      errorText: mapErrors(error, childInviteeEmail),
    }));
  };

  sendInvite = () => {
    const {
      inviteChild,
      onGrowl,
      parentEmail,
      profileId,
      sessionId,
      translate,
    } = this.props;
    if (!this.inviteFPChildEmail || !this.inviteFPChildEmail.value) return;
    const email = this.inviteFPChildEmail.value
      .toLowerCase()
      .split(' ')
      .join(''); // To lowercase, strip whitespace. JC WEB-11783 7/18/18
    const parentEmailLowerCase = parentEmail.toLowerCase().split(' ').join('');

    if (this.inviteFPChildEmail && email === parentEmailLowerCase) {
      this.onSubmitError('CANNOT_INVITE_SELF');
    } else {
      this.setState({ childInviteeEmail: email });
      inviteFPChild(profileId, sessionId, email)
        .then(() => {
          inviteChild({ dateInvited: Date.now(), email });
          this.setState({ expanded: false });
          onGrowl(
            translate('Invite sent to {email}', { email }),
            GrowlIcons.Check,
          );
          if (!this.inviteFPChildEmail || !this.inviteFPChildEmail.value)
            return;
          this.inviteFPChildEmail.value = '';
        })
        .catch((error: any) => {
          const errObj = error instanceof Error ? error : new Error(error);
          logger.error(
            [CONTEXTS.SUBSCRIPTION, CONTEXTS.FAMILY_PLAN],
            error,
            {},
            errObj,
          );
          if (error.response) {
            this.onSubmitError(error.response.data.error);
          }
        });
    }
  };

  onCancel = () => {
    this.setState({ expanded: false });
  };

  checkEmail = () => {
    if (this.inviteFPChildEmail) {
      this.setState({
        validEmail: isValidEmail(this.inviteFPChildEmail.value),
      });
    }
  };

  render() {
    const { translate } = this.props;
    return (
      <FamilyMemberContainer>
        <FamilyMember display>
          <RowMask data-test="row-mask" onClick={this.openCloseToggle} />
          <Row>
            <Add onClick={this.openCloseToggle}>
              <AddIcon />
            </Add>
            <p>
              &nbsp;
              {translate('Add family member...')}
            </p>
          </Row>
        </FamilyMember>
        <FamilyMember display={this.state.expanded}>
          <RowMask data-test="row-mask" onClick={this.openCloseToggle} />
          <Row>
            <Input
              onChange={this.checkEmail}
              placeholder={translate('email@example.com')}
              ref={inviteFPChildEmail => {
                this.inviteFPChildEmail = inviteFPChildEmail;
              }}
              type="text"
            />
          </Row>
          <Row>
            <OutlinedButton marginRight="1rem" onClick={this.onCancel}>
              {translate('Cancel')}
            </OutlinedButton>
            <FilledButton
              disabled={!this.state.validEmail}
              marginRight="1rem"
              onClick={this.sendInvite}
              styleType="cta"
            >
              {translate('Send Invite')}
            </FilledButton>
            <ErrorMessage display={this.state.displayError}>
              {this.state.errorText}
            </ErrorMessage>
          </Row>
        </FamilyMember>
      </FamilyMemberContainer>
    );
  }
}
