import Check from 'styles/icons/Check';
import CheckboxInput from './primitives/CheckboxInput';
import CheckboxWrapper from './primitives/CheckboxWrapper';
import InputBorder from './primitives/InputBorder';
import InputCheckboxText from './primitives/InputCheckboxText';
import ShowHideCheck from './primitives/ShowHideCheck';
import theme from 'styles/themes/default';
import { Component } from 'react';

class Checkbox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    tabIndex: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    checked: false,
    classes: [],
    name: '',
    onChange: undefined,
    tabIndex: undefined,
    value: '',
  };

  state = {
    checked: this.props.checked,
  };

  onChange = e => {
    /**
     * When using setState callbacks in react, you are required to cache
     * synthetic event objects if you need access to them. Otherwise, it is
     * probable that they are dereferenced at the time you need them.
     */
    const event = { ...e };

    this.setState(
      ({ checked }) => ({
        checked: !checked,
      }),
      () => {
        const { onChange } = this.props;
        if (onChange)
          onChange(event.currentTarget.value, this.state.checked, event);
      },
    );
  };

  render() {
    return (
      <label>
        <CheckboxWrapper>
          <ShowHideCheck active={this.state.checked}>
            <Check color={theme.colors.blue.primary} />
          </ShowHideCheck>
          <CheckboxInput
            checked={this.state.checked}
            name={this.props.name}
            onChange={this.onChange}
            tabIndex={this.props.tabIndex}
            type="checkbox"
            value={this.props.value}
          />
          <InputBorder />
        </CheckboxWrapper>
        <InputCheckboxText>
          {this.props.children || this.props.value}
        </InputCheckboxText>
      </label>
    );
  }
}

export default Checkbox;
