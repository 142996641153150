import * as UPSELL_FROM from 'modules/Analytics/constants/upsellFrom';
import AccountDropdown from './components/AccountDropdown';
import analytics from 'modules/Analytics';
import BillingHistoryLink from './components/BillingHistoryLink';
import ChangePlan from './primitives/ChangePlan';
import FamilyPlan from 'components/FamilyPlan';
import P from 'primitives/Typography/BodyCopy/P';
import Section from 'components/Section';
import ShowAccount from './primitives/ShowAccount';
import SubscribeAgainLink from './components/SubscribeAgainLink';
import SubscribeLink from './components/SubscribeLink';
import SubscriptionButtons from './components/SubscriptionButtons';
import UpdatePaymentLink from './components/UpdatePaymentLink';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { format, isBefore } from 'date-fns';
import { getUpgradeLink } from 'state/Links/selectors';
import { SUBSCRIPTION_SOURCE } from 'constants/subscriptionConstants';
import { toggleAccountDropdown } from 'state/UI/actions';
import { useDispatch, useSelector } from 'react-redux';
import type { FunctionComponent, SyntheticEvent } from 'react';
import type { IGetTranslateFunctionResponse } from 'redux-i18n';
import type { State } from 'state/types';

export type OnLinkClick = (event: SyntheticEvent<HTMLAnchorElement>) => void;

type SubscriptionLinkCB = (analyticType?: string) => OnLinkClick;

const upsellFromAnalytics = (type: string) => {
  if (analytics.trackUpsellOpen) {
    analytics.trackUpsellOpen({
      destination: UPSELL_FROM.NEW_SCREEN,
      promotionSubscriptionTier: UPSELL_FROM.GENERAL,
      upsellFrom:
        type === 'subscribeAgain' ?
          UPSELL_FROM.SETTINGS_SUBSCRIPTION_SUBSCRIBE_AGAIN
        : UPSELL_FROM.SETTINGS_SUBSCRIPTION_LEARN_MORE,
      upsellType: UPSELL_FROM.BANNER,
      vendor: UPSELL_FROM.NATIVE,
    });
  }
};

const getPlanText = ({
  isFamilyPlanChild,
  isFamilyPlanParent,
  userSubType,
  translate,
}: {
  isFamilyPlanChild: boolean;
  isFamilyPlanParent: boolean;
  userSubType: string;
  translate: IGetTranslateFunctionResponse;
}) => {
  let planName = translate('Unknown');
  let planDescription = translate('Unknown Plan Description');

  if (isFamilyPlanParent || isFamilyPlanChild) {
    planName = translate('iHeart Family Plan');
    planDescription = translate(
      'You are enjoying an unlimited music experience with an iHeart Family Plan.',
    );
  } else if (userSubType === 'PREMIUM') {
    planName = translate('iHeart All Access');
    planDescription = translate(
      'You are enjoying an unlimited music experience by subscribing to iHeart All Access.',
    );
  } else if (userSubType === 'PLUS') {
    planName = translate('iHeart Plus');
    planDescription = translate(
      'You are subscribed to iHeart Plus. Switch to iHeart All Access to enjoy an unlimited music experience on your computer.',
    );
  } else if (userSubType === 'FREE') {
    planName = translate('iHeart Free');
    planDescription = translate('Subscribe to enjoy unlimited music.');
  }

  return { planName, planDescription };
};

const changePlanMarketMap = {
  [SUBSCRIPTION_SOURCE.APPLE]: 'the App Store',
  [SUBSCRIPTION_SOURCE.AMAZON]: 'Amazon',
  [SUBSCRIPTION_SOURCE.GOOGLE]: 'Google Play',
  [SUBSCRIPTION_SOURCE.ROKU]: 'Roku',
};

type Props = {
  childParentEmail: string | null;
  email: string | null;
  isFamilyPlanChild: boolean;
  isFamilyPlanParent: boolean;
  profileId: number;
  sessionId: string;
  userHasBillingHistory: boolean;
  userIsAutoRenewing: boolean;
  userSubscriptionEndsDate?: string | null;
  userSubSource: string;
  userSubType: string;
};

const SubscriptionSettings: FunctionComponent<Props> = ({
  childParentEmail,
  email,
  isFamilyPlanChild,
  isFamilyPlanParent,
  profileId,
  sessionId,
  userHasBillingHistory,
  userIsAutoRenewing,
  userSubscriptionEndsDate,
  userSubSource,
  userSubType,
}) => {
  const showingAccountDropdown = useSelector<State, boolean>(
    state => state.ui.showingAccountDropdown,
  );
  const translate = useTranslate();
  const upgrade = useSelector(getUpgradeLink);
  const dispatch = useDispatch();

  const isRecurly = userSubSource === 'RECURLY';
  const isFreePlan = userSubType === 'FREE';

  const handleModifyPlanClick = () => {
    if (isRecurly) dispatch(toggleAccountDropdown(!showingAccountDropdown));
  };

  const getOnSubLinkClick: SubscriptionLinkCB = analyticType => e => {
    if (isRecurly) {
      if (analyticType) upsellFromAnalytics(analyticType);
    } else if (e?.preventDefault) {
      e.preventDefault();
    }
  };

  if (!upgrade) {
    return null;
  }

  const { planName, planDescription } = getPlanText({
    translate,
    userSubType,
    isFamilyPlanChild,
    isFamilyPlanParent,
  });

  const changePlanLink = (
    <ShowAccount onClick={handleModifyPlanClick}>Change Plan</ShowAccount>
  );

  const isExpired =
    userSubscriptionEndsDate ?
      isBefore(new Date(userSubscriptionEndsDate), new Date())
    : false;

  const hasMobileSubscription = [
    SUBSCRIPTION_SOURCE.APPLE,
    SUBSCRIPTION_SOURCE.GOOGLE,
    SUBSCRIPTION_SOURCE.AMAZON,
    SUBSCRIPTION_SOURCE.ROKU,
  ].includes(userSubSource);

  const subscriptionEndsString =
    userSubscriptionEndsDate ?
      format(new Date(userSubscriptionEndsDate), 'MMM D, YYYY')
    : null;
  const isCancelled = !userIsAutoRenewing && userSubType !== 'FREE';
  const cancelDateText = translate(
    'Your subscription ends on {subscriptionEndsString}.',
    {
      subscriptionEndsString,
    },
  );

  const userCanChangePlan =
    !isCancelled &&
    !isFreePlan &&
    !isFamilyPlanChild &&
    (isExpired || !hasMobileSubscription);

  const familyPlanHeader =
    isFamilyPlanParent || isFamilyPlanChild ?
      <div css={{ fontWeight: 'bold', marginTop: '3rem' }}>
        {translate('Family Members')}
      </div>
    : null;

  return (
    <Section
      dataTest="settings-section-subscription"
      header={translate('Subscription')}
    >
      <div>
        <span css={{ fontWeight: 'bold' }} data-test="settings-plan-name">
          {planName}
        </span>
        <ChangePlan>{userCanChangePlan ? changePlanLink : null}</ChangePlan>
      </div>
      <div>
        <P color={colors => colors.gray.medium}>
          {!isCancelled ? planDescription : cancelDateText}
        </P>
        <P color={colors => colors.gray.medium}>
          {(
            !isFreePlan &&
            SUBSCRIPTION_SOURCE[
              userSubSource as keyof typeof SUBSCRIPTION_SOURCE
            ]
          ) ?
            translate(
              ` To change your plan or payment method, please visit {store} on your device.`,
              {
                store:
                  changePlanMarketMap[
                    userSubSource as keyof typeof SUBSCRIPTION_SOURCE
                  ],
              },
            )
          : null}
        </P>
        {isFreePlan ?
          <SubscribeLink
            onClick={getOnSubLinkClick('subscribe')}
            upgrade={upgrade}
          />
        : null}
        {isCancelled ?
          <SubscribeAgainLink
            onClick={getOnSubLinkClick('subscribeAgain')}
            upgrade={upgrade}
          />
        : null}
        {!showingAccountDropdown && !isFreePlan ?
          <SubscriptionButtons
            billingLink={<BillingHistoryLink onClick={getOnSubLinkClick()} />}
            isFamilyPlanChild={isFamilyPlanChild}
            isRecurly={isRecurly}
            updateLink={<UpdatePaymentLink onClick={getOnSubLinkClick()} />}
            userHasBillingHistory={userHasBillingHistory}
            userSubSource={userSubSource}
          />
        : null}
      </div>
      {showingAccountDropdown ?
        <AccountDropdown
          handleXClick={handleModifyPlanClick}
          isRecurly={isRecurly}
          key="account-dropdown"
          userSubType={userSubType}
        />
      : null}
      {familyPlanHeader}
      {isFamilyPlanParent ?
        <FamilyPlan
          childParentEmail={null}
          email={email}
          profileId={profileId}
          sessionId={sessionId}
          translate={translate}
        />
      : null}

      {isFamilyPlanChild ?
        <FamilyPlan
          child
          childParentEmail={childParentEmail}
          email={null}
          profileId={profileId}
          sessionId={sessionId}
          translate={translate}
        />
      : null}
    </Section>
  );
};

export default SubscriptionSettings;
