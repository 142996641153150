import styled from '@emotion/styled';

type Props = {
  width?: string;
};

const Input = styled('input')<Props>(({ theme, width = '100%' }) => ({
  backgroundColor: theme.colors.white.primary,
  border: `1px solid ${theme.colors.gray.light}`,
  borderRadius: '.5rem',
  fontSize: '1.6rem',
  height: '3.7rem',
  lineHeight: 'normal',
  outline: 'none',
  padding: '0 1rem',
  width,
}));

export default Input;
