import ShouldShow from 'components/ShouldShow';
import type { FunctionComponent, ReactNode } from 'react';

type Props = {
  billingLink: ReactNode;
  isFamilyPlanChild: boolean;
  isRecurly: boolean;
  updateLink: ReactNode;
  userHasBillingHistory: boolean;
  userSubSource: string;
};

const SubscriptionButtons: FunctionComponent<Props> = ({
  billingLink,
  isFamilyPlanChild,
  isRecurly,
  updateLink,
  userHasBillingHistory,
  userSubSource,
}) => {
  const billingHistory = userHasBillingHistory || isRecurly;

  return (
    <div>
      <ShouldShow shouldShow={billingHistory && !isFamilyPlanChild}>
        <ShouldShow shouldShow={!userSubSource || isRecurly}>
          {updateLink}
        </ShouldShow>
        {billingLink}
      </ShouldShow>
    </div>
  );
};

export default SubscriptionButtons;
