import FilledButton from 'primitives/Buttons/FilledButton';
import UPSELL from 'constants/upsellTracking';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { addParams } from 'utils/url';
import type { FunctionComponent } from 'react';
import type { OnLinkClick } from '../SubscriptionSettings';

type Props = {
  onClick: OnLinkClick;
  upgrade: any;
};

const SubscribeLink: FunctionComponent<Props> = ({ onClick, upgrade }) => {
  const translate = useTranslate();
  const newPath = addParams(upgrade, {
    upsellFrom: UPSELL.SETTINGS_SUBSCRIPTION_SUBSCRIBE_AGAIN.id,
  });

  return (
    <a href={newPath} onClick={onClick}>
      <FilledButton
        data-test="learn-more-button"
        marginTop="1rem"
        styleType="cta"
      >
        {translate('Learn More')}
      </FilledButton>
    </a>
  );
};

export default SubscribeLink;
