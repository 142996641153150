import styled from '@emotion/styled';

export const accountDropdownPadding = '2.5rem 1.5rem';
export const accountDropdownMarginTop = '3rem';

const Dropdown = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.transparent.secondary,
  border: 'none',
  borderBottom: `solid 1px ${theme.colors.gray.medium}`,
  borderColor: theme.colors.transparent.light,
  color: theme.colors.gray.medium,
  fontSize: '1.6rem',
  margin: `${accountDropdownMarginTop} 0 0 0`,
  marginBottom: '3rem',
  marginTop: accountDropdownMarginTop,
  overflowY: 'hidden',
  padding: accountDropdownPadding,
  position: 'relative',
  textAlign: 'center',
}));

export default Dropdown;
