import ExpandArrow from 'styles/icons/ExpandArrow';
import styled from '@emotion/styled';

type Props = {
  inverted: boolean;
};

const ExpandArrowIcon = styled(ExpandArrow)<Props>(({ inverted = false }) => ({
  lineHeight: '1em',
  marginTop: '-.5em',
  position: 'absolute',
  right: '1.5rem',
  top: '50%',
  transform: inverted ? 'rotate(180deg)' : 'none',
}));

export default ExpandArrowIcon;
