export function mapErrors(error, email) {
  const map = {
    CANNOT_INVITE_SELF: "You can't invite yourself to your own Family Plan.",
    CHILD_ALREADY_INVITED: 'You have a pending invitation to [email] already.',
    CHILD_LIMIT_REACHED:
      "You've reached the limit for the number of accounts that you can have on your Family Plan.",
    DUPLICATE_CHILD_EMAIL: `${email} can't be invited again.`,
    INCORRECT_ZIP_CODE: 'The ZIP Code you entered was incorrect.',
    INVALID_INVITATION_TOKEN: 'The invite you were sent is no longer valid.',
    NOT_PARENT: "You're not authorized to take that action.",
    TEST_ERROR_MESSAGE: 'test error message', // Used for FamilyMemberBlank.test.jsx
  };
  return map[error] || error.toLowerCase().replace(/_/g, ' ');
}
