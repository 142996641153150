import styled from '@emotion/styled';

const CheckboxInput = styled('input')(({ theme }) => ({
  backgroundColor: theme.colors.white.primary,
  border: `1px solid ${theme.colors.gray['300']}`,
  display: 'inline-block',
  height: '100%',
  left: '0',
  margin: '0',
  opacity: '0',
  padding: '0',
  position: 'absolute',
  top: '0',
  verticalAlign: 'middle',
  width: '70%',
}));

export default CheckboxInput;
