import styled from '@emotion/styled';

const SettingsList = styled('ul')({
  display: 'block',
  margin: 'auto',
  listStyleType: 'none',
  padding: 0,
  textAlign: 'left',
  width: '90%',
});

export default SettingsList;
