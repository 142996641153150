import styled from '@emotion/styled';

const Row = styled('div')({
  alignItems: 'center',
  display: 'flex',
  input: {
    zIndex: 1,
  },
  padding: '1rem',
  width: '100%',
});

export default Row;
