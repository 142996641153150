import styled from '@emotion/styled';

const InputBorder = styled('span')(({ theme }) => ({
  backgroundColor: theme.colors.white.primary,
  border: `1px solid ${theme.colors.gray[300]}`,
  borderRadius: '.5rem',
  height: '3rem',
  left: '0',
  pointerEvents: 'none',
  position: 'absolute',
  top: '0',
  transition: 'border-color 0.5s ease',
  width: '3rem',
  zIndex: 2,
}));

export default InputBorder;
