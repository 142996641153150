import Avatar from 'components/FamilyPlan/primitives/Avatar';
import Description from 'components/FamilyPlan/primitives/Description';
import Email from 'components/FamilyPlan/primitives/Email';
import FamilyMember from 'components/FamilyPlan/primitives/FamilyMember';
import FamilyMemberContainer from 'components/FamilyPlan/primitives/FamilyMemberContainer';
import ProfilePic from 'components/ProfilePic/ProfilePic';
import Row from 'components/FamilyPlan/primitives/Row';
import useTranslate from 'contexts/TranslateContext/useTranslate';

type Props = {
  childParentEmail: string;
  email: string;
};

function FamilyPlanAdmin({ childParentEmail, email }: Props) {
  const translate = useTranslate();

  return (
    <FamilyMemberContainer>
      <FamilyMember display>
        <Row>
          <Avatar>
            <ProfilePic size={30} />
          </Avatar>
          <Email>{childParentEmail || email}</Email>
          <Description>{translate('Owner')}</Description>
        </Row>
      </FamilyMember>
    </FamilyMemberContainer>
  );
}

export default FamilyPlanAdmin;
