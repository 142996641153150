import Carrot from 'components/FamilyPlan/primitives/Carrot';
import Description from 'components/FamilyPlan/primitives/Description';
import Email from 'components/FamilyPlan/primitives/Email';
import EmailIcon from 'components/FamilyPlan/primitives/EmailIcon';
import Envelope from 'styles/icons/Envelope';
import ExpandArrowIcon from './primitives/ExpandArrowIcon';
import FamilyMember from 'components/FamilyPlan/primitives/FamilyMember';
import FamilyMemberContainer from 'components/FamilyPlan/primitives/FamilyMemberContainer';
import FilledButton from 'primitives/Buttons/FilledButton';
import format from 'date-fns/format';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import Row from 'components/FamilyPlan/primitives/Row';
import { Child } from './types';
import { Component } from 'react';
import { GrowlIcons } from 'components/Growls/constants';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { inviteFPChild, removeFPChild } from 'shared/api/subscription';

type State = {
  expanded: boolean;
};

type Props = {
  inviteChild: (child: Child) => void;
  member: Child;
  onGrowl: (title: string, icon: GrowlIcons) => void;
  profileId: number;
  removeChild: (email: string) => void;
  sessionId: string;
  translate: IGetTranslateFunctionResponse;
};

export default class FamilyMemberPending extends Component<Props, State> {
  state = {
    expanded: false,
  };

  openCloseToggle = () =>
    this.setState(({ expanded }) => ({ expanded: !expanded }));

  resend = () => {
    const {
      inviteChild,
      member,
      removeChild,
      onGrowl,
      profileId,
      sessionId,
      translate,
    } = this.props;
    const { email } = member;
    removeFPChild(profileId, sessionId, email).then(() => {
      removeChild(email);
      inviteFPChild(profileId, sessionId, email).then(() => {
        inviteChild({ dateInvited: Date.now(), email });
        this.setState({ expanded: false });
        onGrowl(
          translate('Invite sent to {email}', { email }),
          GrowlIcons.Check,
        );
      });
    });
  };

  onRemoveChild = () => {
    const { member, onGrowl, profileId, removeChild, sessionId, translate } =
      this.props;
    const { email } = member;
    removeChild(member.email);
    removeFPChild(profileId, sessionId, email);
    this.setState({ expanded: false });
    onGrowl(
      translate('{email} has been removed.', { email }),
      GrowlIcons.Close,
    );
  };

  render() {
    const { member, translate } = this.props;
    const buttons =
      member.dateAccepted ?
        <Row>
          <OutlinedButton marginRight="1rem" onClick={this.onRemoveChild}>
            {translate('Remove Member')}
          </OutlinedButton>
        </Row>
      : <Row>
          <OutlinedButton marginRight="1rem" onClick={this.onRemoveChild}>
            {translate('Cancel Invite')}
          </OutlinedButton>

          <FilledButton
            marginRight="1rem"
            onClick={this.resend}
            styleType="cta"
          >
            {translate('Resend Invite')}
          </FilledButton>
        </Row>;

    const info =
      member.dateAccepted ? null : (
        <Row>
          <p>
            {translate('Invite was sent on ')}
            {format(new Date(member.dateInvited), 'MM/DD/YYYY')}
          </p>
        </Row>
      );
    return (
      <FamilyMemberContainer>
        <FamilyMember display>
          <Row onClick={this.openCloseToggle}>
            <EmailIcon>
              <Envelope />
            </EmailIcon>
            <Email>{member.email}</Email>
            <Description>
              {member.dateAccepted ?
                translate('Active ✓')
              : translate('Pending...')}
            </Description>
            <Carrot>
              <ExpandArrowIcon inverted={this.state.expanded} />
            </Carrot>
          </Row>
        </FamilyMember>
        <FamilyMember
          display={this.state.expanded}
          onClick={this.openCloseToggle}
        >
          {info}
          {buttons}
        </FamilyMember>
      </FamilyMemberContainer>
    );
  }
}
