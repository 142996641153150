import Dropdown from '../primitives/Dropdown';
import FilledButton from 'primitives/Buttons/FilledButton';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import paths from 'router/Routes/paths';
import UpsellHeading from '../primitives/UpsellHeading';
import XButton from '../primitives/XButton';
import { ConnectedModals } from 'state/UI/constants';
import { navigateToSubscriptionFrame } from 'utils/subscription/index';
import { openModal } from 'state/UI/actions';
import { RECURLY_SUBSCRIPTION_ID } from 'constants/subscriptionConstants';
import { useDispatch } from 'react-redux';
import type { FunctionComponent } from 'react';

type Props = {
  isRecurly: boolean;
  handleXClick: () => void;
  userSubType: string;
};

function startUpgradeFlow(isRecurly: boolean) {
  if (isRecurly) {
    window.location.href = `${paths.recurly.subscribe}?subscriptionId=${RECURLY_SUBSCRIPTION_ID.IHEART_US_PREMIUM}`;
  } else {
    navigateToSubscriptionFrame('IHEART_US_PREMIUM', {
      nextPage: '/your-library/',
    });
  }
}

const AccountDropdown: FunctionComponent<Props> = props => {
  const { handleXClick, isRecurly, userSubType } = props;
  const dispatch = useDispatch();

  const startDowngradeFlow = () => {
    dispatch(
      openModal({
        id: ConnectedModals.Downgrade,
        context: null,
      }),
    );
  };

  const showCancelModal = () => {
    dispatch(
      openModal({
        id: ConnectedModals.Cancel,
        context: null,
      }),
    );
  };

  return (
    <Dropdown key="account-dropdown">
      <UpsellHeading>Update your subscription</UpsellHeading>
      <XButton onClick={handleXClick} />
      <OutlinedButton isBlock onClick={showCancelModal}>
        Cancel Subscription
      </OutlinedButton>
      {userSubType === 'PREMIUM' ?
        <FilledButton
          isBlock
          marginTop="1rem"
          onClick={startDowngradeFlow}
          styleType="cta"
        >
          Switch to iHeart Plus
        </FilledButton>
      : <FilledButton
          isBlock
          marginTop="1rem"
          onClick={() => startUpgradeFlow(isRecurly)}
          styleType="cta"
        >
          Switch to iHeart All Access
        </FilledButton>
      }
    </Dropdown>
  );
};

export default AccountDropdown;
