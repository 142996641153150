import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Helmet } from 'react-helmet';
import { translateKeywords } from 'utils/metaUtils';

function Head() {
  const translate = useTranslate();

  const title = translate('Settings | See Your Music Profile');
  const description = translate(
    'Log in and see which live radio stations and custom artist stations you love. You can also view your listening history. All free.',
  );
  const keywords = translateKeywords(
    translate,
    [
      'iHeartRadio',
      'iHeart',
      'Radio',
      'Internet',
      'Music',
      'Talk',
      'Listen',
      'Live',
      'Artist',
      'Song',
      'Playlist',
      'On Demand',
      'Discover',
      'Personalized',
      'Free',
      'App',
      'Online',
    ].join(','),
  );

  return (
    <>
      <GenericHead
        description={description}
        metaDescription={description}
        noAltLink
        ogType="music.playlist"
        title={title}
      />
      <Helmet>
        <meta content={keywords} name="keywords" />
      </Helmet>
    </>
  );
}

export default Head;
