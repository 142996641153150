import AccountItem from './primitives/AccountItem';
import Checkbox from 'web-ui/templates/FormElements/Checkbox';
import Connections from './Connections';
import countryCodes from 'constants/countryCodes';
import FilledButton from 'primitives/Buttons/FilledButton';
import GenreItem from './primitives/GenreItem';
import Input from 'web-ui/templates/FormElements/Input';
import InputIcon from './primitives/InputIcon';
import NavLink from 'components/NavLink';
import P from 'primitives/Typography/BodyCopy/P';
import Pencil from 'styles/icons/Pencil';
import PrivacyOptions from '../../../../styles/icons/PrivacyOptions';
import Section from 'components/Section';
import SectionHeader from 'primitives/Typography/Headings/SectionHeader';
import SettingsList from './primitives/SettingsList';
import ShouldShow from 'components/ShouldShow/ShouldShow';
import SubscriptionSettings from '../SubscriptionSettings';
import theme from 'styles/themes/default';
import WarningIcon from 'styles/icons/Warning';
import { AlertContexts } from 'state/UI/constants';
import { ChangeEvent, Component } from 'react';
import {
  countryNumberPlaceholder,
  formatPhoneNumber,
} from 'components/ChangeContactInfoModal/helpers';
import { get } from 'lodash-es';
import { getChildParentEmail } from 'shared/api/subscription';
import { getInstance as getSocialInstance } from 'shared/services/Social';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { showNotifyGrowl as showNotifyGrowlAction } from 'state/UI/actions';
import type { AccountType } from 'state/Profile/types';
import type { Genre } from 'state/Genres/types';
import type { Navigate } from 'state/Routing/types';

const social = getSocialInstance();

export type Props = {
  accountType?: AccountType;
  ampUrl: string;
  appMounted: boolean;
  countryCode: string;
  email?: string | null;
  facebookId?: string | null;
  fetchGenrePreferences: () => Promise<void>;
  genres: Array<Genre>;
  genresRequest: () => Promise<void>;
  isFamilyPlanChild: boolean;
  isFamilyPlanParent: boolean;
  isLoggedOut: boolean;
  navigate: Navigate;
  openAddEmailAndPasswordModal: () => void;
  openAlertModal: (context: AlertContexts) => void;
  openChangeEmailModal: () => void;
  openChangeContactInfoModal: () => void;
  openChangePasswordModal: () => void;
  piiRegulation: { dashboardLink: string; enabled: boolean };
  preferences: Record<string, any>;
  profileId: number | null;
  savePreference: (preference: string, isChecked: boolean) => void;
  selectedGenres: { [id: string]: boolean | undefined } | undefined;
  sessionId: string | null;
  shareProfile?: string | null;
  onDemandEnabled: boolean;
  showNotifyGrowl: typeof showNotifyGrowlAction;
  translate: IGetTranslateFunctionResponse;
  userHasBillingHistory: boolean;
  userIsAutoRenewing: boolean;
  userSubscriptionEndsDate?: string | null;
  userSubSource: string;
  userSubType: string;
  saveGenrePreferences: (
    genresSelected: { [key: string]: boolean | undefined },
    updateType: 'update' | 'onboarding',
  ) => Promise<void>;
  name?: string;
  phoneNumber?: string;
  postalCode?: string | null;
  countryPhoneInfo: {
    callingCode: string;
    format?: string;
  };
};

type State = {
  activeGenres?: {
    [key: string]: boolean | undefined;
  };
  childParentEmail: string;
  email: string | null | undefined;
};

export default class SettingsSection extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      childParentEmail: '',
      email: props.email!,
    };
  }

  componentDidMount() {
    const { ampUrl, isFamilyPlanChild, profileId, sessionId } = this.props;

    social
      .isReady()
      .catch(() => {})
      .then(() => {
        const { selectedGenres = {}, fetchGenrePreferences } = this.props;
        return Promise.all([
          Object.keys(selectedGenres).length ?
            fetchGenrePreferences()
          : Promise.resolve(),
          this.props.genres.length ?
            Promise.resolve()
          : this.props.genresRequest(),
        ]).then(() =>
          this.setState({ activeGenres: this.props.selectedGenres || {} }),
        );
      });

    if (isFamilyPlanChild) {
      getChildParentEmail({ ampUrl, profileId, sessionId }).then(({ data }) => {
        const childParentEmail = get(data, 'email');
        this.setState({ childParentEmail });
      });
    }
  }

  componentDidUpdate() {
    if (this.props.email !== this.state.email) {
      this.setState({
        email: this.props.email,
      });
    }
  }

  onClickAddEmailAndPassword = () => {
    const { openAddEmailAndPasswordModal } = this.props;
    openAddEmailAndPasswordModal();
  };

  onClickChangeEmail = () => {
    const { openChangeEmailModal } = this.props;
    openChangeEmailModal();
  };

  onClickChangeContactInfo = () => {
    const { openChangeContactInfoModal } = this.props;
    openChangeContactInfoModal();
  };

  onClickChangePassword = () => {
    const { openChangePasswordModal } = this.props;
    openChangePasswordModal();
  };

  onGenreChange = (
    value: string,
    isChecked: boolean,
    ev: ChangeEvent<HTMLInputElement>,
  ) => {
    const { saveGenrePreferences } = this.props;

    const actives = { ...(this.state.activeGenres || {}) }; // eslint-disable-line react/no-access-state-in-setstate

    const label =
      ev.target.labels ?
        ev.target.labels[0]
      : ev.target.parentElement!.parentElement;
    const genre = label!.textContent || label!.innerText;

    if (isChecked) {
      actives[value] = true;
    } else {
      delete actives[value];
    }

    this.setState({ activeGenres: actives }, async () => {
      const { showNotifyGrowl, translate } = this.props;
      await saveGenrePreferences(actives, 'update');
      const title =
        isChecked ? translate('Genre added.') : translate('Genre removed.');

      const description =
        isChecked ?
          translate("You've added {genreName} to your genres.", {
            genreName: genre,
          })
        : translate("You've removed {genreName} from your genres.", {
            genreName: genre,
          });

      showNotifyGrowl({
        title,
        description,
      });
    });
  };

  onPrefChange = (pref: string, isChecked: boolean) => {
    const { savePreference, showNotifyGrowl, translate } = this.props;

    savePreference(pref, isChecked);
    showNotifyGrowl({
      title: translate('Update successful.'),
      description: translate('Your preferences have been changed.'),
    });
  };

  render() {
    const {
      accountType,
      countryCode,
      facebookId,
      onDemandEnabled,
      piiRegulation,
      preferences,
      userSubType,
      userSubSource,
      userHasBillingHistory,
      userSubscriptionEndsDate,
      userIsAutoRenewing,
      translate,
      profileId,
      sessionId,
      isFamilyPlanParent,
      isFamilyPlanChild,
      genres,
      appMounted,
      name,
      phoneNumber,
      postalCode,
      countryPhoneInfo,
    } = this.props;

    const emailPlaceholder = translate('Not provided');
    const {
      activeGenres,
      childParentEmail,
      email = emailPlaceholder,
    } = this.state;
    let fbSharingOpts;
    const fbUnlinkDisabled = !!facebookId && !this.state.email;
    const genreComponents =
      activeGenres ?
        genres.map(genre => (
          <GenreItem key={`genre|${genre.id}`}>
            <Checkbox
              checked={!!activeGenres[genre.id]}
              onChange={this.onGenreChange}
              value={genre.id}
            >
              {genre.name}
            </Checkbox>
          </GenreItem>
        ))
      : null;
    if (preferences && !!facebookId) {
      fbSharingOpts = (
        <div>
          <div>
            <Checkbox
              checked={!!parseInt(preferences['fb.publishing'], 10)}
              onChange={this.onPrefChange}
              value="fb.publishing"
            >
              {translate(
                'Publish my listening activity to my Facebook music dashboard',
              )}
            </Checkbox>
          </div>
        </div>
      );
    }

    const inputStyles = {
      display: 'inline-block',
      height: '3.2rem',
      verticalAlign: 'middle',
      width: 'calc(70% - 1.6rem)',
      color: theme.colors.gray['450'],
    };
    const labelStyles = {
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '30%',
      textAlign: 'right',
      paddingRight: '1rem',
    };

    return (
      <div style={{ width: '100%' }}>
        <Section
          dataTest="settings-section-settings"
          header={translate('Settings')}
        >
          <P color={colors => colors.gray.medium!}>
            {translate(
              'Update your password, genre selection, and modify your subscription below.',
            )}
          </P>
        </Section>
        <Section
          dataTest="settings-section-account"
          header={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <SectionHeader>{translate('Account')}</SectionHeader>
            </div>
          }
        >
          {this.state.email ?
            <SettingsList>
              <AccountItem data-test="acc-section-username">
                {/* Force input re-render */}
                <Input
                  dataTest="settings-email"
                  disabled
                  inputStyles={inputStyles}
                  key={email || undefined}
                  labelStyles={labelStyles}
                  name="userName"
                  tabIndex={1}
                  title={translate('Email')}
                  type="email"
                  value={email}
                />
                <InputIcon
                  data-test="settings-pencil-icon-email"
                  onClick={this.onClickChangeEmail}
                  tabIndex={2}
                >
                  <Pencil fill={theme.colors.gray.medium} />
                </InputIcon>
              </AccountItem>
              <AccountItem data-test="acc-section-password">
                <Input
                  dataTest="settings-password"
                  disabled
                  inputStyles={inputStyles}
                  labelStyles={labelStyles}
                  name="password"
                  tabIndex={3}
                  title={translate('Password')}
                  type="password"
                  value={translate('Password')}
                />
                <InputIcon
                  data-test="settings-pencil-icon-email"
                  onClick={this.onClickChangePassword}
                  tabIndex={2}
                >
                  <Pencil fill={theme.colors.gray.medium} />
                </InputIcon>
              </AccountItem>
              {piiRegulation.enabled && (
                <AccountItem
                  data-test="acc-section-optout"
                  style={{ paddingLeft: '30%' }}
                >
                  <NavLink tabIndex={5} to={piiRegulation.dashboardLink}>
                    {translate('Your Privacy Choices')}
                    <PrivacyOptions />
                  </NavLink>
                </AccountItem>
              )}
            </SettingsList>
          : <div css={{ background: theme.colors.white.primary }}>
              <div
                css={{
                  float: 'left',
                  height: '6rem',
                  padding: '2.8rem 2.4rem',
                  width: '3rem',
                }}
              >
                <WarningIcon />
              </div>
              <div css={{ padding: '2.6rem 5.6rem .5rem' }}>
                <h3>
                  {translate(
                    "You're currently signed in with a social account, add an email and password to have full access to your account",
                  )}
                </h3>
              </div>
              <div
                css={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '1.4rem 2.4rem',
                }}
              >
                <FilledButton
                  aria-label={translate('Add New Email')}
                  marginTop="1rem"
                  onClick={this.onClickAddEmailAndPassword}
                  styleType="cta"
                  tabIndex={6}
                >
                  {translate('Add New Email')}
                </FilledButton>
              </div>
            </div>
          }
        </Section>
        <ShouldShow
          key="section-contact-info"
          shouldShow={accountType === 'IHR' && appMounted}
        >
          <Section
            dataTest="settings-section-contact-info"
            header={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <SectionHeader>
                  {translate('Contact Information')}
                </SectionHeader>
                <InputIcon
                  data-test="settings-pencil-icon-email"
                  onClick={this.onClickChangeContactInfo}
                  sectionHeader
                  tabIndex={2}
                >
                  <Pencil fill={theme.colors.gray.medium} />
                </InputIcon>
              </div>
            }
            subheader={translate(
              'Update your contact information to personalize your experience and easily enter iHeart contests and promotions.',
            )}
          >
            <SettingsList>
              <AccountItem data-test="acc-section-first-name">
                {/* Force input re-render */}
                <Input
                  dataTest="setting-full-name-input"
                  disabled
                  inputStyles={inputStyles}
                  key="fullName"
                  labelStyles={labelStyles}
                  name="fullName"
                  tabIndex={7}
                  title={translate('First and Last Name')}
                  type="text"
                  value={
                    name && name !== email ?
                      name
                    : translate('Add your full name')
                  }
                />
              </AccountItem>
              <AccountItem data-test="acc-section-phone-number">
                {/* Force input re-render */}
                <Input
                  dataTest="settings-phone-number"
                  disabled
                  inputStyles={inputStyles}
                  key="phoneNumber"
                  labelStyles={labelStyles}
                  name="phoneNumber"
                  tabIndex={9}
                  title={translate('Phone Number')}
                  type="tel"
                  value={
                    (
                      phoneNumber &&
                      phoneNumber &&
                      phoneNumber.length >
                        (countryPhoneInfo?.callingCode?.length ?? 1) + 2
                    ) ?
                      formatPhoneNumber(countryPhoneInfo, phoneNumber)
                    : countryNumberPlaceholder(countryPhoneInfo)
                  }
                />
              </AccountItem>
              <ShouldShow shouldShow={countryCode === countryCodes.CA}>
                <AccountItem>
                  {/* Force input re-render */}
                  <Input
                    disabled
                    inputStyles={inputStyles}
                    key="postalCode"
                    labelStyles={labelStyles}
                    name="postalCode"
                    tabIndex={9}
                    title={translate('Postal Code')}
                    type="string"
                    value={postalCode || translate('Add your postal code')}
                  />
                </AccountItem>
              </ShouldShow>
            </SettingsList>
          </Section>
        </ShouldShow>
        {onDemandEnabled && appMounted ?
          <SubscriptionSettings
            childParentEmail={childParentEmail}
            email={email}
            isFamilyPlanChild={isFamilyPlanChild}
            isFamilyPlanParent={isFamilyPlanParent}
            profileId={profileId!}
            sessionId={sessionId!}
            userHasBillingHistory={userHasBillingHistory}
            userIsAutoRenewing={userIsAutoRenewing}
            userSubscriptionEndsDate={userSubscriptionEndsDate}
            userSubSource={userSubSource}
            userSubType={userSubType}
          />
        : null}
        <Connections
          fbSharingOpts={fbSharingOpts}
          fbUnlinkDisabled={fbUnlinkDisabled}
        />
        <Section
          dataTest="settings-section-mygenres"
          header={translate('My Genres')}
          subheader={translate(
            'Tell us all your favorite genres so we can make iHeart perfect for you.',
          )}
        >
          <div>
            <SettingsList>{genreComponents}</SettingsList>
            <NavLink title={translate('iHeart')} to="/">
              <FilledButton
                center
                data-test="view-recommendations-button"
                styleType="cta"
              >
                {translate('View Recommendations')}
              </FilledButton>
            </NavLink>
          </div>
        </Section>
      </div>
    );
  }
}
