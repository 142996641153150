import styled from '@emotion/styled';

type Props = {
  display?: boolean;
};

const FamilyMember = styled('div')<Props>(({ display = false }) => ({
  '.image': {
    borderRadius: '50%',
    overflow: 'hidden',
    width: '6rem',
  },

  maxHeight: display ? '20rem' : '0rem',
  overflow: 'auto',
  position: 'relative',
  transition: 'all 0.5s ease-in-out',
}));

export default FamilyMember;
