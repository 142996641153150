import ConnectionItem from './primitives/ConnectionItem';
import FilledButton from 'primitives/Buttons/FilledButton';
import P from 'primitives/Typography/BodyCopy/P';
import Section from 'components/Section';
import SettingsList from './primitives/SettingsList';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getFacebookId, getGooglePlusId } from 'state/Profile/selectors';
import { getInstance as getSocialInstance } from 'shared/services/Social';
import { useSelector } from 'react-redux';
import type { FunctionComponent, ReactNode } from 'react';

const social = getSocialInstance();

type Props = {
  fbUnlinkDisabled: boolean;
  fbSharingOpts?: ReactNode;
};

const Connections: FunctionComponent<Props> = ({
  fbUnlinkDisabled,
  fbSharingOpts,
}) => {
  const translate = useTranslate();

  const facebookId = useSelector(getFacebookId);
  const googlePlusId = useSelector(getGooglePlusId);

  const onSocialUnlinked = () => {
    setTimeout(() => {
      // Refresh, let's give it a delay to let them read the growl
      window?.location?.reload?.();
    }, 2_000);
  };

  const onGoogleClick = () => {
    if (googlePlusId) {
      social.unlink('gplus').then(onSocialUnlinked);
    } else {
      social.link('gplus');
    }
  };

  const onFbClick = () => {
    if (facebookId) {
      social
        .unlink('fb')
        .then(onSocialUnlinked)
        .catch(() => {});
    } else {
      social.link('fb');
    }
  };

  return (
    <Section
      dataTest="settings-section-connections"
      header={translate('Connections')}
    >
      <SettingsList>
        <ConnectionItem>
          <div style={{ width: '100%', marginBottom: '1.5rem' }}>
            <P>
              {translate('Facebook')}
              <FilledButton
                aria-label={
                  facebookId ? translate('Unlink') : translate('Link')
                }
                data-test="fb-link-unlink-button"
                disabled={fbUnlinkDisabled}
                marginLeft="1rem"
                onClick={onFbClick}
                size="small"
                styleType="cta"
              >
                {facebookId ? translate('Unlink') : translate('Link')}
              </FilledButton>
            </P>
          </div>
          <div style={{ width: '100%' }}>{fbSharingOpts}</div>
        </ConnectionItem>
        <ConnectionItem>
          <P>
            {translate('Google')}
            <FilledButton
              aria-label={
                googlePlusId ? translate('Unlink') : translate('Link')
              }
              data-test="google-link-unlink-button"
              marginLeft="1rem"
              onClick={onGoogleClick}
              size="small"
              styleType="cta"
            >
              {googlePlusId ? translate('Unlink') : translate('Link')}
            </FilledButton>
          </P>
        </ConnectionItem>
      </SettingsList>
    </Section>
  );
};

export default Connections;
