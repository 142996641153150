import styled from '@emotion/styled';

const AccountItem = styled('li')(({ theme }) => ({
  color: theme.colors.gray.medium,
  position: 'relative',
  marginLeft: 0,
  label: {
    paddingRight: '1rem',
    textAlign: 'right',
  },
}));

export default AccountItem;
