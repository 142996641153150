import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const UpsellHeading = styled('h4')(({ theme }) => ({
  color: theme.colors.gray.dark,
  display: 'inline-block',
  fontSize: '2.2rem',
  fontWeight: 'bold',
  paddingBottom: '1.7rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['320'])]: {
    fontSize: '1.6rem',
  },
}));

export default UpsellHeading;
