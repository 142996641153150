import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import paths from 'router/Routes/paths';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import type { FunctionComponent } from 'react';
import type { OnLinkClick } from '../SubscriptionSettings';

type Props = {
  onClick: OnLinkClick;
};

const UpdatePaymentLink: FunctionComponent<Props> = ({ onClick }) => {
  const translate = useTranslate();

  return (
    <a href={paths.recurly.updatePayment} onClick={onClick}>
      <OutlinedButton marginRight="1rem" marginTop="1rem">
        {translate('Update Payment')}
      </OutlinedButton>
    </a>
  );
};

export default UpdatePaymentLink;
