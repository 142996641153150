import styled from '@emotion/styled';

const CheckboxWrapper = styled('span')({
  display: 'block',
  float: 'left',
  height: '3.2rem',
  marginRight: '1rem',
  position: 'relative',
  width: '3.2rem',
});

export default CheckboxWrapper;
