/**
 * Subscription API Amp interface (ie. playlists)
 */

import composeRequest, {
  authHeaders,
  body,
  header,
  method,
  query,
  urlTagged,
} from 'api/helpers';
import getPowerAmpUrl from 'utils/hacks/getPowerAmpUrl';
import transport from 'api/transport';

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Add an subscription without validating receipt/token data.
 * Only used for testing.
 */
export function updateSubscription(
  profileId,
  sessionId,
  userSubType,
  userSubSource,
  expirationDate,
  isTrial,
  endpoint = 'http://ampinternal.ihrprod.net',
) {
  const sub = {
    expirationDate: Number(expirationDate),
    isTrial,
    profileId: Number(profileId),
    source: userSubSource,
    subscriptionType: userSubType,
    upsellFrom: 0,
  };

  return transport(
    composeRequest(
      urlTagged`${{
        ampInternal: endpoint,
      }}/internal/api/v3/subscription/internalSubscription`,
      method('post'),
      authHeaders(profileId, sessionId),
      header('Accept', 'application/json'),
      header('Content-Type', 'application/json'),
      body(sub),
    )(),
  ).then(checkStatus);
}

/**
 * Delete user subscription data from database. Makes user eligible for trial
 * Only used for testing.
 */
export function deleteSubscription(
  profileId,
  sessionId,
  endpoint = 'http://ampinternal.ihrprod.net',
) {
  return transport(
    composeRequest(
      urlTagged`${{
        ampInternal: endpoint,
      }}/internal/api/v3/subscription/subscriptions/${{
        profileId,
      }}`,
      method('delete'),
      authHeaders(profileId, sessionId),
      header('Accept', 'application/json'),
      header('Content-Type', 'application/json'),
    )(),
  ).then(checkStatus);
}

/**
 * Family Plan list children of Parent (admin) User.
 */
export function listFPChildren(profileId, sessionId) {
  return transport(
    composeRequest(
      urlTagged`${{ ampUrl: getPowerAmpUrl() }}subscription/familyPlan/members`,
      method('get'),
      authHeaders(profileId, sessionId),
      header('Accept', 'application/json'),
      header('Content-Type', 'application/json'),
    )(),
  ).then(checkStatus);
}

/**
 * Family Plan invite child to plan.
 */
export function inviteFPChild(profileId, sessionId, childEmail) {
  return transport(
    composeRequest(
      urlTagged`${{ ampUrl: getPowerAmpUrl() }}subscription/familyPlan/members`,
      method('post'),
      authHeaders(profileId, sessionId),
      header('Accept', 'application/json'),
      header('Content-Type', 'application/json'),
      query({ email: childEmail }),
    )(),
  );
}

/**
 * Family Plan remove child from plan.
 */
export function removeFPChild(profileId, sessionId, childEmail) {
  return transport(
    composeRequest(
      urlTagged`${{ ampUrl: getPowerAmpUrl() }}subscription/familyPlan/members`,
      method('delete'),
      authHeaders(profileId, sessionId),
      header('Accept', 'application/json'),
      header('Content-Type', 'application/json'),
      query({ email: childEmail }),
    )(),
  ).then(checkStatus);
}

/**
 * Family Plan child accept invitation.
 */
export function acceptInvitation({
  ampUrl,
  profileId,
  sessionId,
  token,
  zipcode,
}) {
  return transport(
    composeRequest(
      urlTagged`${{ ampUrl }}/api/v3/subscription/familyPlan/acceptInvitation`,
      method('post'),
      authHeaders(profileId, sessionId),
      header('Accept', 'application/json'),
      header('Content-Type', 'application/json'),
      query({ token, zipCode: zipcode }),
    )(),
  ).then(checkStatus);
}

export function getChildParentEmail({ ampUrl, profileId, sessionId }) {
  return transport(
    composeRequest(
      urlTagged`${{ ampUrl }}/api/v3/subscription/familyPlan/parent`,
      method('get'),
      authHeaders(profileId, sessionId),
      header('Accept', 'application/json'),
      header('Content-Type', 'application/json'),
    )(),
  ).then(checkStatus);
}
