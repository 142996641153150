import SettingsSection, { Props } from './SettingsSection';
import { connect } from 'react-redux';
import { ConnectedModals } from 'state/UI/constants';
import { createStructuredSelector } from 'reselect';
import {
  fetchGenrePreferences,
  genresRequest,
  saveGenrePreferences,
} from 'state/Genres/actions';
import { flowRight } from 'lodash-es';
import {
  getAccountType,
  getEmail,
  getFacebookId,
  getName,
  getPhoneNumber,
  getPreferences,
  getShareProfile,
  getZipCode,
} from 'state/Profile/selectors';
import {
  getAmpUrl,
  getCountryCode,
  getCountryPhoneInfo,
  getPIIRegulationConfig,
} from 'state/Config/selectors';
import { getAppMounted } from 'state/UI/selectors';
import {
  getAutoRenewing,
  getExpiration,
  getHasBillingHistory,
  getIsFamilyPlanChild,
  getIsFamilyPlanParent,
  getSource,
  getSubscriptionType,
} from 'state/Entitlements/selectors';
import { getGenresList, getSelectedGenres } from 'state/Genres/selectors';
import { getOnDemandEnabled } from 'state/Features/selectors';
import { getProfileId, getSessionId } from 'state/Session/selectors';
import { localize } from 'redux-i18n';
import { navigate } from 'state/Routing/actions';
import { openModal, showNotifyGrowl } from 'state/UI/actions';
import { savePreference } from 'state/Profile/actions';
import type { AlertContexts } from 'state/UI/constants';
import type { State, ThunkDispatch } from 'state/types';

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  fetchGenrePreferences() {
    dispatch(fetchGenrePreferences());
  },
  genresRequest() {
    dispatch(genresRequest());
  },
  navigate(payload: { path: string; replace?: boolean }) {
    dispatch(navigate(payload as any));
  },
  saveGenrePreferences(
    genresSelected: { [key: string]: boolean },
    updateType: 'update' | 'onboarding',
  ): Promise<void> {
    return dispatch(saveGenrePreferences(genresSelected, updateType));
  },
  savePreference(key: string, value: boolean) {
    dispatch(savePreference(key, value));
  },
  openAlertModal(context: AlertContexts) {
    dispatch(
      openModal({
        id: ConnectedModals.Alert,
        context,
      }),
    );
  },
  openChangeEmailModal() {
    dispatch(
      openModal({
        id: ConnectedModals.ChangeEmail,
        context: null,
      }),
    );
  },
  openChangeContactInfoModal() {
    dispatch(
      openModal({
        id: ConnectedModals.ChangeContactInfo,
        context: null,
      }),
    );
  },
  openChangePasswordModal() {
    dispatch(openModal({ id: ConnectedModals.ChangePassword, context: null }));
  },
  openAddEmailAndPasswordModal() {
    dispatch(
      openModal({ id: ConnectedModals.AddEmailAndPassword, context: null }),
    );
  },
  showNotifyGrowl(growl: Parameters<typeof showNotifyGrowl>[0]) {
    dispatch(showNotifyGrowl(growl));
  },
});

export default flowRight(
  localize('translate'),
  connect(
    createStructuredSelector<
      State,
      Pick<
        Props,
        | 'accountType'
        | 'ampUrl'
        | 'appMounted'
        | 'countryCode'
        | 'email'
        | 'facebookId'
        | 'isFamilyPlanChild'
        | 'isFamilyPlanParent'
        | 'piiRegulation'
        | 'preferences'
        | 'profileId'
        | 'sessionId'
        | 'shareProfile'
        | 'onDemandEnabled'
        | 'userHasBillingHistory'
        | 'userIsAutoRenewing'
        | 'userSubscriptionEndsDate'
        | 'userSubSource'
        | 'userSubType'
        | 'selectedGenres'
        | 'genres'
        | 'name'
        | 'phoneNumber'
        | 'countryPhoneInfo'
        | 'postalCode'
      >
    >({
      accountType: getAccountType,
      ampUrl: getAmpUrl,
      appMounted: getAppMounted,
      countryCode: getCountryCode,
      email: getEmail,
      facebookId: getFacebookId,
      genres: getGenresList,
      isFamilyPlanChild: getIsFamilyPlanChild,
      isFamilyPlanParent: getIsFamilyPlanParent,
      piiRegulation: getPIIRegulationConfig,
      preferences: getPreferences,
      profileId: getProfileId,
      selectedGenres: getSelectedGenres,
      sessionId: getSessionId,
      shareProfile: getShareProfile,
      onDemandEnabled: getOnDemandEnabled,
      userHasBillingHistory: getHasBillingHistory,
      userIsAutoRenewing: getAutoRenewing,
      userSubscriptionEndsDate: getExpiration,
      userSubSource: getSource,
      userSubType: getSubscriptionType,
      name: getName,
      phoneNumber: getPhoneNumber,
      countryPhoneInfo: getCountryPhoneInfo,
      postalCode: getZipCode,
    }),
    mapDispatchToProps,
  ),
)(SettingsSection);
