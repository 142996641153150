import styled from '@emotion/styled';

type Props = {
  display?: boolean;
};

const DisplayMessage = styled('div')<Props>(({ display = false, theme }) => ({
  color: theme.colors.red.primary,
  display: display ? 'block' : 'none',
}));

export default DisplayMessage;
