import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const InputCheckboxText = styled('span')(({ theme }) => ({
  display: 'inline-block',
  lineHeight: '1.75rem',
  marginTop: '.5rem',
  maxWidth: '86%',
  verticalAlign: 'middle',

  [mediaQueryBuilder(theme.mediaQueries.max.width['370'])]: {
    maxWidth: '80%',
  },

  a: {
    whiteSpace: 'nowrap',
  },
}));

export default InputCheckboxText;
