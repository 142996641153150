import styled from '@emotion/styled';

const InputIcon = styled('span')(
  ({ sectionHeader }: { sectionHeader?: boolean }) => ({
    cursor: 'pointer',
    ...(sectionHeader ?
      {}
    : {
        position: 'absolute',
        left: 'calc(105.555% - 19px);',
        top: '0.5rem',
      }),
  }),
);

export default InputIcon;
