import styled from '@emotion/styled';

const Carrot = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  fontSize: theme.fonts.size.small,
  margin: '0 1rem 0 1rem',
  width: '1rem',
}));

export default Carrot;
