import styled from '@emotion/styled';

type Props = { active: boolean };

const ShowHideCheck = styled('div')<Props>(({ active }) => ({
  display: active ? 'block' : 'none',
  position: 'relative',
  zIndex: 3,
  svg: {
    margin: '0.8rem 0.8rem .7rem .6rem',
  },
}));

export default ShowHideCheck;
