import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import UPSELL from 'constants/upsellTracking';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { addParams } from 'utils/url';
import type { FunctionComponent } from 'react';
import type { OnLinkClick } from '../SubscriptionSettings';

type Props = {
  onClick: OnLinkClick;
  upgrade: any;
};

const SubscribeAgainLink: FunctionComponent<Props> = ({ onClick, upgrade }) => {
  const translate = useTranslate();
  const newPath = addParams(upgrade, {
    upsellFrom: UPSELL.SETTINGS_SUBSCRIPTION_SUBSCRIBE_AGAIN.id,
  });

  return (
    <a aria-label="Subscribe Again" href={newPath} onClick={onClick}>
      <OutlinedButton marginRight="1rem">
        {translate('Subscribe Again')}
      </OutlinedButton>
    </a>
  );
};

export default SubscribeAgainLink;
