import styled from '@emotion/styled';

const SettingsGenresList = styled('li')({
  display: 'block',
  float: 'left',
  marginBottom: '1.5rem',
  width: '50%',
});

export default SettingsGenresList;
