import styled from '@emotion/styled';

const ChangePlan = styled('span')(({ theme }) => ({
  a: {
    color: theme.colors.blue.primary,
    cursor: 'pointer',
    fontWeight: 'bold',
    marginLeft: '1rem',
  },
}));

export default ChangePlan;
