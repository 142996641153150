import styled from '@emotion/styled';

const RowMask = styled('div')({
  height: '100%',
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 0,
});

export default RowMask;
