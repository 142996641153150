import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import paths from 'router/Routes/paths';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import type { FunctionComponent } from 'react';
import type { OnLinkClick } from '../SubscriptionSettings';

type Props = {
  onClick: OnLinkClick;
};

const BillingHistoryLink: FunctionComponent<Props> = ({ onClick }) => {
  const translate = useTranslate();

  return (
    <a href={paths.recurly.history} onClick={onClick}>
      <OutlinedButton marginRight="1rem">
        {translate('Billing History')}
      </OutlinedButton>
    </a>
  );
};

export default BillingHistoryLink;
