import BaseXButton from 'components/Buttons/XButton/XButton';
import styled from '@emotion/styled';

const XButton = styled(BaseXButton)({
  height: '1.9rem',
  opacity: 0.5,
  position: 'absolute',
  right: '1.5rem',
  top: '1.5rem',
  width: '1.9rem',
});

export default XButton;
